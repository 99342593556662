import React from 'react'
import Layout from '../layouts/layout.js'
import Helmet from 'react-helmet'
import Pagetitle from '../components/Pagetitle'

const Services = () => (
  <Layout>
    <div>
      <Helmet title="Services" />
      <Pagetitle title="Services" />
      <p>We offer a variety of services ranging from Branding and Web Development through to Online order processing and fulfilment solutions</p>
      <h2>Branding</h2>
      <h2>Web Development</h2>
      <h2>Social Media</h2>
      <h2>Digital Signage Solutions</h2>
      <h2>Shopify Eccomerce Solutions</h2>
      <h2>Task Automation Solutions using Integromat</h2>

    </div>
  </Layout>
)

export default Services
